<template>
  
  <div class="note-header py-3 flex items-center gap-6">
    <div class="flex items-center gap-3 flex-1">
      <span class="user flex gap-2 items-center">
        <i class="fas fa-user fa-sm"></i>
        <b>{{ note.display_name }}</b>
      </span>

      <time class="text-sm text-gray-500" :datetime="note.created_on">
        {{ formatDate(note.created_on) }}
      </time>
    </div>

    <div class="note-actions flex items-center justify-self-end" v-if="logged_in">
      <button class="btn btn-text btn-sm" @click="editNote(note.id)">
        <i class="fas fa-pencil fa-xs"></i>
        Edit
      </button>

      <Popover class="relative" v-slot="{ open, close }">
        <PopoverButton :class="['btn btn-sm btn-text btn-destructive', {'btn-active': open}]">
          <i class="fas fa-trash-alt fa-xs"></i>
          Delete
        </PopoverButton>
        <PopoverPanel class="popover popover-centered rounded-2xl p-3">
          <i class="arrow"></i>
          <button class="btn btn-destructive whitespace-nowrap" @click="handleDelete(close)">Delete Note</button>
        </PopoverPanel>
      </Popover>
    </div>
  </div>

  <div class="note-body md-content mt-3" v-if="note.html" v-html="note.html"></div>

  <ul class="flex flex-wrap gap-2 list-none mt-8" v-if="note.attachments.length > 0">
    <li v-for="attachment in note.attachments" :key="attachment.id" class="contents">
      <file-chip v-bind="attachment" />
    </li>
  </ul>

</template>

<script>
import formatDate from '../lib/formatDate'
import MyPopover from './ConfirmPopover.vue'
import FileChip from './FileChip.vue'

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { deleteData } from '../lib/fetch'

export default {
  components: { FileChip, MyPopover, Popover, PopoverButton, PopoverPanel }, 
  emits: ['delete'],
  inject: ['logged_in', 'removeNote', 'ticketID', 'editNote'],
  methods: {
    formatDate,
    async handleDelete(closePopover) {
      deleteData(`/tickets/${this.ticketID}/notes/${this.note.id}`).then(response => {
        if(response.success) {
          closePopover()
          this.removeNote(this.note.id)
        }
      })
    },
  },
  props: ['note'],
}
</script>