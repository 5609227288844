<template>
<div class="popover-bg" @click="dismiss"></div>
<div class="popover" ref="popover">
  <div class="arrow" data-popper-arrow></div>
  <slot />
</div>
</template>

<style scoped>
.popover-bg {
  background-color: #0000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<script>
import { createPopper } from '@popperjs/core';

export default {
  mounted() {
    this.setUpPopover()
  },
  unmounted() {
    if(this.__popper) this.__popper.destroy();
  },
  props: {
    element: HTMLElement,
    proxy: HTMLElement,
    label: {
      type: String,
      default: "Confirm"
    }
  },
  emits: ['dismiss'],
  methods: {
    handleConfirm() {
      console.log("Confirming")
      this.$emit("confirm")
      this.element.click();
    },
    setUpPopover() {
      this.__popper = createPopper(this.proxy, this.$refs.popover, {placement: 'bottom'} )
    },
    dismiss() {
      this.__popper.destroy()
      this.$emit('dismiss')
    }
  },
}
</script>