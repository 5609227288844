
async function fetchData(url = '', method = 'GET', data = {}) {
  let options = {
    method,
    cache: 'no-cache',
  }


  const csrfMeta = document.querySelector('meta[name="csrf-token"]')
  const csrfToken = csrfMeta.getAttribute('content')

  if(method !== 'GET' && method !== 'HEAD') {
    options.headers = {
      'X-CSRF-Token': csrfToken,
      'Accept': 'application/json'
    }

    if(data instanceof FormData) {
      options.body = data
    } else {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(data)
    }
  }

  const response = await fetch(url, options);

  if(response.status < 400) {
    return response.json();
  } else {
    throw new Error(`Request error ${response.status}`)
  }
}

export async function getData(url = '') {
  return fetchData(url, 'GET')
}

export async function postData(url = '', data = {}) {
  return fetchData(url, 'POST', data);
}

export async function deleteData(url = '', data = {}) {
  return fetchData(url, 'DELETE', data);
}

export async function patchData(url = '', data = {}) {
  return fetchData(url, 'PATCH', data);
}