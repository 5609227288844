import { createApp } from 'vue';
import TicketDetail from '../components/TicketDetail.vue'

document.addEventListener('turbo:load', loadEvent => {
  console.log(loadEvent)
  console.log(window.__ticket_data)

  let ticketDetailFrame = document.querySelector('#ticket-detail-frame')
  if(ticketDetailFrame) {
    createApp(TicketDetail).mount(ticketDetailFrame)
  }

  let ticketForm = document.querySelector('form')
  ticketForm.addEventListener('turbo:submit-start', () => {
    console.log("Submitting form!")
    window.scrollTo(0, 0);
  })
})