<template>
  <HarvestTimer :ticket="ticket" v-if="isLoggedIn && enable_harvest" />

  <StatusEditor :loggedIn="isLoggedIn" :currentStatus="status" :statusOptions="states" />

  <div class="sidebar-section space-y-1">
    <h4>Customer Info</h4>

    <div><a :href="`mailto:${ticket.customer_email}`" class="no-underline hover:underline text-gray-800">{{ ticket.customer_name }}</a></div>

    <div class="info-row truncate">
      <i class="fas fa-envelope fa-sm fa-fw mr-1 text-gray-300"></i>
      <a :href="`mailto:${ticket.customer_email}`" class="no-underline hover:underline">{{ ticket.customer_email }}</a>
    </div>

    <div class="info-row" v-if="ticket.phone_number">
      <i class="fas fa-phone fa-sm fa-fw mr-1 text-gray-300"></i>
      {{ ticket.phone_number }}
    </div>
  </div>

  <div class="sidebar-section template-info" v-if="isLoggedIn && hasTemplate">
    <h4>Template</h4>
    <a :href="templateURL">{{ ticket.template_name }}</a>
  </div>

  <AssignmentsEditor :assignedUserIDs="assignedUserIDs" @add-user="assignUser" @remove-user="unassignUser" />
  <ParticipantsEditor :participants="ticket.participants" @saveParticipant="saveParticipant" />
</template>

<style scoped>

.info-row {
  @apply text-sm;  
  a { color: inherit; }
}

</style>

<script>
import pick from 'lodash/pick'
import { postData, deleteData } from '../lib/fetch'
import ParticipantsEditor from './participants_editor.vue'
import AssignmentsEditor from './AssignmentEditor.vue'
import StatusEditor from './status_editor.vue'
import HarvestTimer from './HarvestTimer.vue'

export default {
  components: { ParticipantsEditor, AssignmentsEditor, StatusEditor, HarvestTimer },
  inject: ['logged_in', 'app'],
  props: {
    ticket: Object,
    application_name: String,
    harvest_app_id: String,
    enable_harvest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: this.ticket.status,
      assignedUserIDs: this.ticket.assigned_users
    }
  },
  computed: {
    users() {
      return this.app.users
    },
    states() {
      return this.app.states || []
    },
    ticketID() { return this.ticket.id },
    editTicketURL() {
      return `/tickets/${this.ticketID}/edit`
    },
    hasTemplate() {
      return !!this.ticket.template_id
    },
    templateURL() {
      return `/ticket_templates/${this.ticket.template_id}`
    },
    isLoggedIn() {
      return !!this.logged_in
    },
    assignmentsURL() {
      return `/tickets/${this.ticketID}/assignments.json`
    },
  },
  methods: {
    async assignUser(id) {
      await postData(this.assignmentsURL, {user_id: id})
      this.assignedUserIDs.push(id)
    },
    async unassignUser(idToUnassign) {
      await deleteData(this.assignmentsURL, {user_id: idToUnassign})
      this.assignedUserIDs = this.assignedUserIDs.filter(id => id !== idToUnassign)
    },
    saveParticipant(participantData) {
      const participant = pick(participantData, ['id', 'name', 'email', 'role'])
      const participants = this.ticket.participants
      const arrayIndex = participants.findIndex(function(p) { return p.id === participant.id })

      if(arrayIndex >= 0) {
        participants.splice(arrayIndex, 1, participant)
      } else {
        participants.push(participant)
      }
    },
    editNote(noteId) {
      this.$refs.noteEditor.presentNote(noteId)
    }
  }
}
</script>