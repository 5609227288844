<template>
  <div class="sidebar-section ticket-status">
    <h4>Ticket Status</h4>
    <div>
      <div class="ticket-status">{{ status }}</div>
      
      <Popover class="mt-1 relative inline-block" v-if="loggedIn" v-slot="{ close }">
        <PopoverButton class="btn btn-mini btn-soft edit-status">
          Update status
        </PopoverButton>
        <PopoverPanel class="popover left-[50%] ml-[-100%]">
          <form ref="form" @submit.prevent="saveStatus(close)" :action="changeStatusURL" method="post" id="status-popover" class="w-[200px]">
            <select name="status" id="edit_status" v-model="status" class="w-full text-lg">
              <option value="" disabled></option>
              <option v-for="state in statusOptions" :key="state">{{ state }}</option>
            </select>

            <div class="notification_toggle mt-3 flex gap-1 items-center">
              <input type="hidden" name="notification" value="0">
              <input type="checkbox" name="notification" id="notification" value="1" v-model="sendEmail" />
              <label for="notification">
                Send email
              </label>
            </div>

            <div class="flex gap-1 mt-3">
              <button type="submit" class="btn btn-sm">Update</button>
              <PopoverButton class="btn btn-sm btn-soft">Cancel</PopoverButton>
            </div>
          </form>
        </PopoverPanel>
      </Popover>
    </div>
  </div>
</template>

<script>
import { postData } from '../lib/fetch'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export default {
  components: { Popover, PopoverButton, PopoverPanel },
  inject: ['ticketID'],
  props: {
    currentStatus: String,
    statusOptions: Array,
    ticketId: String,
    loggedIn: Boolean
  },
  data() {
    return {
      status: this.currentStatus,
      isEditing: false,
      sendEmail: false
    }
  },
  mounted() {
    document.addEventListener('click', this.handleCancelClick)
  },
  unmounted() {
    document.removeEventListener('click', this.handleCancelClick)
  },
  computed: {
    changeStatusURL() {
      return `/tickets/${this.ticketID}/change_status.json`
    },
    showPopover() {
      return this.loggedIn && this.isEditing
    }
  },
  methods: {
    saveStatus(closePopover) {
      const { form } = this.$refs
      const formData = new FormData(form)

      // TODO: Handle notification checkbox
      postData(this.changeStatusURL, formData)
        .then(data => {
          this.isEditing = false
          form.reset()
          closePopover()
        })
    },
    handleCancelClick(e) {
      if(this.isEditing) {
        const targetElem = e.target
        const popoverIfParent = targetElem.closest('#status-popover')
        if(!popoverIfParent) {
          e.stopPropagation()
          e.preventDefault()
          this.isEditing = false
          this.status = this.currentStatus
        }
      }
    }
  }
}
</script>