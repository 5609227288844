<template>
<div class="ticket-detail my-8">
  <div class="rounded-box">

    <div class="ticket-header flex items-center">
      <header class="flex-1">
        <h1 class="ticket-heading text-lg font-medium text-gray-500">
          {{ ticketTitle }}
        </h1>
      </header>
      <div class="header-section actions" v-if="logged_in">
        <a :href="`/tickets/${ticket.id}/edit`" class="btn btn-text">
          <i class="fas fa-pencil"></i>
          Edit Ticket
        </a>
      </div>
    </div>

    <div class="ticket-body mb-12">
      <h4 class="text-2xl font-semibold">{{ ticket.subject }}</h4>

      <div class="mt-8 md-content" v-html="ticket.html"></div>

      <ul class="flex flex-wrap gap-2 list-none mt-8" v-if="ticket.attachments.length > 0">
        <li v-for="attachment in ticket.attachments" :key="attachment.id" class="contents">
          <FileChip v-bind="attachment" />
        </li>
      </ul>

      <div class="bg-yellow-100 mt-8 p-6 -mx-6" v-if="logged_in && hasInternalNotes">
        <h4 class="type-label">Internal Notes</h4>
        <div class="md-content" v-html="ticket.internal_notes_html"></div>
      </div>
    </div>

    <div class="ticket-notes-container">
      <ul class="ticket-notes list-none">
        <transition-group name="notes-list">
          <li class="note" v-for="note in notes" :key="`ticket-notes-${note.id}`">
            <note-item :note="note" />
          </li>
        </transition-group>
      </ul>

      <div class="create-note">
        <hr class="my-10 border-0 border-t border-solid border-gray-300" />

        <create-note :ticketId="ticket.id" />
      </div>
    </div>

  </div>

  <div id="ticket-sidebar" class="space-y-6 pt-6">
    <ticket-sidebar :ticket="ticket" />
  </div>

  <NoteEditor ref="noteEditor" />
</div>
</template>

<style>
.notes-list-enter-active,
.notes-list-leave-active {
  transition: all 0.5s ease;
}

.notes-list-enter-from,
.notes-list-leave-to {
  opacity: 0;
  /* transform: translateY(30px); */
}

.notes-list-enter-from {
  background-color: #ff0;
}
</style>

<script>
import CreateNote from './CreateNote.vue'
import NoteItem from './NoteItem.vue'
import TicketSidebar from './TicketSidebar.vue'
import NoteEditor from './NoteEditor.vue'
import FileChip from './FileChip.vue'

export default {
  components: { CreateNote, TicketSidebar, NoteItem, NoteEditor, FileChip },
  data() {
    let users = []
    const { ticket, logged_in, app } = window.__ticket_data

    if(app) {
      users = app.users
    }

    return {
      ticket, 
      logged_in,
      notes: (ticket.notes || []),
      users,
      app: (app || { states: [], users: [] }),
    }
  },
  provide() {
    return {
      logged_in: this.logged_in,
      removeNote: this.removeNote,
      addNote: this.addNote,
      editNote: this.editNote,
      app: this.app,
      ticketID: this.ticket.id,
      updateNote: this.updateNote
    }
  },
  computed: {
    ticketTitle() {
      return `Ticket #${this.ticket.id}`
    },
    hasInternalNotes() {
      return (typeof this.ticket.internal_notes === 'string' && this.ticket.internal_notes.length > 0)
    }
  },
  methods: {
    removeNote(id) {
      const noteIndex = this.notes.findIndex(n => (n.id === id))
      this.notes.splice(noteIndex, 1)
    },
    addNote(note) {
      this.notes.push(note)
    },
    editNote(noteId) {
      this.$refs.noteEditor.presentNote(noteId)
    },
    updateNote(note) {
      const noteIndex = this.notes.findIndex(n => (n.id === note.id))
      this.notes.splice(noteIndex, 1, note)
    }
  }
}
</script>