<template>
  <h3 class="text-base font-medium mb-1">{{ title }} {{ draggedItemId }}</h3>
  <draggable 
    tag="transition-group" 
    v-model="items"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    @change="handleSortChange"
    item-key="_id" 
    class="space-y-3 mb-3">
    <template #item="{ element: item, index }">
      <template-item 
        v-bind="item" 
        :index="index" 
        :key="item._id"
        :data-id="item._id" 
        :itemCount="itemCount" 
        :removeCallback="removeItemAtIndex(index)"
        />
    </template>
  </draggable>
  <div class="px-4">
    <button type="button" @click="addField()" class="btn btn-mini btn-text">
      <i class="fas fa-plus"></i>
      Add Field
    </button>
  </div>
</template>

<style scoped>
.tpl-items-move {
  transition: transform 0.8s ease;
}
.ghost {
  opacity: 0.5;
}
</style>

<script>
import TemplateItem from './template_item.vue'
import draggable from 'vuedraggable'
import ShortUniqueId from 'short-unique-id'
import _ from 'lodash'

const uid = new ShortUniqueId({ length: 10 });

function makeTemplateItem(attrs = {}) {  
  attrs._id = uid()
  return Object.assign({}, attrs)
}

function arrayMove(arr, fromIndex, toIndex) {
    let newArray = [...arr]; // copy the array
    var element = newArray[fromIndex];
    newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, element);
    return newArray;
}

export default {
  components: { TemplateItem, draggable },
  data() {
    let templateData;
    const templateDataElem = document.querySelector("#template-json-data")
    if(templateDataElem) {
      templateData = JSON.parse(templateDataElem.innerHTML).map(makeTemplateItem)
    } else {
      templateData = [ makeTemplateItem() ]
    }

    return {
      title: "Custom Fields",
      items: templateData,
      draggedItemId: null,
      dropTargetId: null,
      drag: false
    }
  },
  computed: {
    itemCount() {
      return this.items.length
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        componentData: {
          name: !this.drag ? 'tpl-items' : null,
          tag: 'ul',
          type: 'transition-group'
        }
      };
    }
  },
  methods: {
    addField() {
      this.items.push( makeTemplateItem() )
    },
    removeItemAtIndex(index) {
      const editor = this
      return function() {
        console.log(`Removing item at index ${index}`)
        editor.items.splice(index, 1);
      }
    },
    handleSortChange({ moved: { element, oldIndex, newIndex }}) {
      // console.log({ element: element._id, oldIndex, newIndex })
      const newItems = arrayMove(this.items, oldIndex, newIndex)
      console.log(this.items, newItems)
    }
  }
}
</script>