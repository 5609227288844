<template>
  <Dialog :open="visible" @close="$emit('close')" class="fixed inset-0 z-10 overflow-y-auto m-0 border-0">
    <div class="flex flex-col items-center justify-center min-h-screen w-screen">
      <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
      <div class="bg-white w-full max-w-2xl min-h-[300px] relative rounded-xl p-6">
        <DialogTitle class="type-label mb-8" v-if="title">{{ title }}</DialogTitle>
        <slot></slot>
      </div>
    </div>
  </Dialog>

  <!-- <teleport to="#dialog-content" v-if="visible">
    <div class="scrim bg-black bg-opacity-50 fixed inset-0 p-8 flex flex-col place-items-center place-content-center" @click.self="closeDialog">
      <div class="dialog-wrapper dialog bg-white border-solid border-2 border-white assignments-dialog w-full max-w-xl flex flex-col rounded-md" ref="dialogContent">
        <header class="flex px-6 pt-4 pb-2">
          <div class="title flex-1 type-label">{{ title }}</div>
          <button @click="closeDialog">
            <i class="fas fa-times"></i>
          </button>
        </header>
        <div class="px-6">
          <slot></slot>
        </div>
      </div>
    </div>
  </teleport> -->
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
} from "@headlessui/vue";

export default {
  components: { 
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription 
  },
  emits: ['close'],
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: true,
    },
    closeCallback: Function
  },
  methods: {
    closeDialog() {
      console.log('Closing dialog')
      if(this.closeCallback) this.closeCallback()
      this.$emit('close')
    },
    handleClickOutside(e) {
      e.stopPropagation()

      if(this.visible) {
        const dialogContent = this.$refs.dialogContent
        console.log(dialogContent)
        if(dialogContent && !dialogContent.contains(e.target)) {
          this.closeDialog()
        }
      }
    }
  }
}
</script>