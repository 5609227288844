<template>
  <div class="sidebar-section" v-if="isLoggedIn || hasAssignedUsers">
    <h4>Assigned To</h4>
    <div class="text-gray-500 text-sm" v-if="assignedUsers.length === 0">
      No one yet
      <!-- -
      <button class="btn btn-link">assign yourself</button> -->
    </div>

    <ul v-if="assignedUsers.length > 0" class="list-none m-0 space-y-1">
      <li v-for="user in assignedUsers" :key="user.id">
        <i class="fas fa-user fa-xs text-gray-300 mr-1"></i>
        {{ user.name }}
      </li>
    </ul>

    <div class="mt-2 flex flex-col gap-2 justify-start items-start" v-if="isLoggedIn">
      <button type="button" @click="showDialog" class="btn btn-mini btn-soft">Edit assignments</button>
    </div>
  </div>
  
  <DialogFrame title="Edit Assignments" :visible="!!showAssignmentsDialog" @close="showAssignmentsDialog = false">

    <div>
      <ul class="list-none flex-1"> 
        <li v-for="user in users" :key="user.id" class="list-style-none m-0 py-3 flex">
          <div class="flex-1">
            <div class="name">{{ user.name }}</div>
          </div>
          <div class="toggle-assignment">
            <button @click="toggleAssignment(user.id)" :class="buttonClass(user.id)" v-text="userIsAssigned(user.id) ? 'Remove' : 'Assign'"></button>
          </div>
        </li>
      </ul>
    </div>

  </DialogFrame>
</template>

<script>
import arrayIncludes from 'lodash/includes'
import DialogFrame from './dialog_frame.vue'

export default {
  components: { DialogFrame },
  data() {
    return {
      showAssignmentsDialog: false
    }
  },
  props: { 
    assignedUserIDs: Array
  },
  emits: ['addUser', 'removeUser'],
  inject: ['logged_in', 'ticketID', 'app'],
  methods: {
    showDialog() {
      this.showAssignmentsDialog = true
    },
    buttonClass(userId) {
      return {
        'btn': true,
        'btn-mini': true,
        'btn-active': this.userIsAssigned(userId),
        'btn-inactive': !this.userIsAssigned(userId)
      }
    },
    userIsAssigned(userId) {
      return arrayIncludes(this.assignedUserIDs, userId)
    },
    toggleAssignment(userId) {
      if(this.userIsAssigned(userId)) {
        this.$emit('removeUser', userId)
      } else {
        this.$emit('addUser', userId)
      }
    }
  },
  computed: {
    users() { return this.app.users },
    isLoggedIn() { return !!this.logged_in },
    hasAssignedUsers() {
      return (this.assignedUsers.length > 0)
    },
    assignedUsers() {
      return this.users.filter(user => arrayIncludes(this.assignedUserIDs, user.id))
    },
    unassignedUsers() {
      return this.users.filter(user => !arrayIncludes(this.assignedUserIDs, user.id))
    }
  }
}
</script>