<template>
  <li class="template-field bg-gray-50 border border-solid border-gray-200 rounded-lg py-2 px-3" :id="rowID">
    <div class="fields-top flex gap-4">
      <div class="field-name flex-1">
        <label :for="fieldID('name')" class="text-sm text-gray-500 mb-1 block">Field Name</label>
        <input type="text"
          :id="fieldID('name')"
          :name="fieldName('name')"
          v-model.trim="values.name"
          placeholder="Field name"
          class="w-full h-10" />
        <input type="hidden"
          :name="fieldName('slug')"
          :value="generatedSlug" /> 
      </div>
      <div class="field-type">
        <label :for="fieldID('type')">Field Type</label>
        <select :name="fieldName('type')" :id="fieldID('type')" v-model="values.type" class="h-10">
          <option value="textfield">Text Field</option>
          <option value="textarea">Text Area</option>
          <option value="select">Drop-Down List</option>
          <option value="file">File Upload</option>
        </select>
      </div>
      <div class="field-optional">
        <label :for="fieldID('optional')">Optional</label>
        <input type="hidden" value="0" :name="fieldName('optional')" />
        <div class="h-10 flex place-items-center">
          <input type="checkbox" value="1" v-model="values.optional" :name="fieldName('optional')" :id="fieldID('optional')" class="switch" />
        </div>
      </div>
      <div class="field-actions">
        <button type="button" class="p-2 rounded bg-gray-100 text-gray-300 hover:text-red-500 hover:bg-red-200" @click="removeCallback()" :disabled="itemCount < 2">
          <i class="fas fa-trash-alt"></i>
          <span class="sr-only">Delete</span>
        </button>
      </div>
    </div>
    <div class="field-options mt-3" :style="{display: (isSelect ? 'block' : 'none')}">
      <label :for="fieldID('options')">Options for drop-down list</label>
      <textarea :name="fieldName('options')" :id="fieldID('options')" cols="40" rows="3" v-model="values.options" :disabled="!isSelect"></textarea>
    </div>
  </li>
</template>

<style scoped>
label {
 @apply text-sm text-gray-500 mb-1 block;
}
</style>

<script>
export default {
  props: {
    itemCount: Number,
    name: String,
    type: String,
    slug: String,
    options: String,
    optional: String,
    index: Number,
    removeCallback: Function
  },
  data() {
    return {
      values: {
        name: (this.name || ""),
        type: (this.type || "textfield"),
        slug: (this.slug || ""),
        optional: (this.optional === "1"),
        options: (this.options || "")
      }
    }
  },
  computed: {
    isSelect() {
      return this.values.type === "select"
    },
    rowID() {
      return 'template_field_' + this.index
    },
    generatedSlug() {
      const _name = this.values.name || ""
      return _name.toLowerCase().replace(/[^a-z0-9]+/gi, '-')
    }
  },
  methods: {
    fieldID(key) {
      return `ticket_template__template_items__${this.index}__${key}`
    },
    fieldName(key) {
      return `ticket_template[template_items][${this.index}][${key}]`
    }
  }
}
</script>