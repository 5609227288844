<template>
  <div class="sidebar-section time-tracking">
    <button class="btn btn-mini btn-orange" :data-item="itemJSON" ref="timerButton">
      Track Time in Harvest
    </button>
  </div> 
</template>

<script>
export default {
  mounted() {
    var event = new CustomEvent("harvest-event:timers:add", {
      detail: { element: this.$refs.timerButton }
    });
    document.querySelector("#harvest-messaging").dispatchEvent(event);
  },
  props: ['ticket'],
  computed: {
    harvestItemID() {
      return `tix-item-${this.ticket.id}`
    },
    itemJSON() {
      return JSON.stringify({
        id: this.harvestItemID,
        name: this.ticket.subject
      })
    }
  }
}
</script>