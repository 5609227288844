<template>
  <DialogFrame title="Edit Note" :visible="showDialog" @close="resetForm">
    <form ref="form" @submit.prevent="saveChanges" class="flex flex-col gap-4">
      <div>
        <textarea name="note[body]" id="note_body" class="w-full" v-model="body"></textarea>
      </div>
      <div class="actions flex gap-3 pb-3">
        <button class="btn">
          <i class="fas fa-check"></i>
          Update Note
        </button>
        <button class="btn btn-soft" @click.prevent="resetForm">
          Cancel
        </button>
      </div>
    </form>
  </DialogFrame>
</template>

<script>
import DialogFrame from './dialog_frame.vue'
import { getData, patchData } from '../lib/fetch'

const defaultValues = {
  showDialog: false,
  noteID: null,
  loaded: false,
  body: '',
  author_name: '',
  author_email: '',
  display_name: ''
};

export default {
  components: {
    DialogFrame,
  },
  inject: ['ticketID', 'updateNote'],
  data() {
    return this.getDefaults() 
  },
  computed: {
    noteURL() {
      return `/tickets/${this.ticketID}/notes/${this.noteID}.json`
    }
  },
  methods: {
    getDefaults() {
      return Object.assign({}, defaultValues)
    },
    resetForm() {
      Object.assign(this, this.getDefaults())
    },
    async presentNote(noteID) {
      this.noteID = noteID
      
      const noteData = await getData(this.noteURL)
      const { author_name, author_email, display_name, body } = noteData
      
      Object.assign(this, { author_name, author_email, display_name, body, loaded: true, showDialog: true })
    },
    async saveChanges() {
      const response = await patchData(this.noteURL, { note: { body: this.body }})
      if(response.success) {
        this.updateNote(response.note)
        this.resetForm()
      }
    }
  }
}
</script>