<template>
  <a :href="url" :title="filename" class="p-2 rounded-lg bg-gray-50 no-underline text-sm inline-flex gap-2 items-center">
    <i class="fas fa-paperclip fa-xs"></i>
    <span>{{ displayName }}</span>
    <button v-if="allowRemove" class="px-1 rounded hover:bg-gray-200" @click.prevent="$emit('remove', id)">
      <i class="fas fa-times fa-sm"></i>
    </button>
  </a>
</template>

<script>
export default {
  props: ['id', 'url', 'filename', 'allowRemove'],
  emits: ['remove'],
  computed: {
    displayName() {
      if(this.filename.length < 24) {
        return this.filename
      }

      let parts = [...this.filename.split('.')]
      let extname = parts.pop()
      let basename = parts.join('.')

      // File basename is at least 18 chars, so get first 8, last 8
      let shortname = [basename.substring(0, 8), '...', basename.substring(basename.length - 8)].join('')

      return `${shortname}.${extname}`
    }
  }
}
</script>