<template>
  <div class="sidebar-section participants" v-if="isLoggedIn">
    <h4>Participants</h4>
    <ul class="participants-list m-0 list-none">
      <li v-for="p in participants" :key="p.id" class="participant-item group flex gap-2 mb-2">
        <span>
          <span class="participant-name">
            <a :href="`mailto:${p.email}`" class="participant-link">{{ p.name }}</a>
          </span>
        </span>
        <span class="participant-actions hidden group-hover:flex focus-within:flex gap-2 items-center">
          <button @click="editParticipant(p.id)" class="text-sm text-blue-600 px-1 rounded leading-none">
            <i class="fas fa-pencil fa-xs"></i>
            <span class="sr-only">Edit</span>
          </button>
          <!-- <button @click="removeParticipant(p.id)" class="text-sm text-red-600 px-1 rounded leading-none">
            <i class="fas fa-trash-alt fa-xs"></i>
            <span class="sr-only">Remove</span>
          </button> -->
        </span>
      </li>
    </ul>

    <button type="button" class="btn btn-soft btn-mini" @click="e => addParticipant()">Add participant</button>
  </div>

  <Dialog :open="isVisible" @close="cancelEditingParticipant()" class="fixed inset-0 z-10 overflow-y-auto m-0 border-0">
    <div class="flex flex-col items-center justify-center min-h-screen w-screen">
      <DialogOverlay class="fixed inset-0 bg-black opacity-30" />
      <div class="bg-white w-full max-w-2xl min-h-[300px] relative rounded-xl p-6">
        <DialogTitle class="type-label mb-8">{{ dialogTitle }}</DialogTitle>
        <form :action="formAction" :method="formMethod" @submit.prevent.stop="saveParticipant" class="pb-2">
          <fieldset class="editor-fields border-0">
            <div class="control-group">
              <label for="new-participant-name">Name</label>
              <input type="text" id="new-participant-name" name="participant[name]" v-model="editableParticipant.name" class="w-full" />
            </div>
            <div class="control-group">
              <label for="new-participant-email">Email</label>
              <input type="text" id="new-participant-email" name="participant[email]" v-model="editableParticipant.email" class="w-full" />
            </div>
            <div class="control-group">
              <label for="new-participant-role">Role</label>
              <input type="text" id="new-participant-role" name="participant[role]" v-model="editableParticipant.role" class="w-full" />
            </div>
          </fieldset>
          <div class="form-actions flex gap-3">
            <button type="submit" class="btn btn-primary">Save participant</button>
            <button class="btn btn-soft" @click="cancelEditingParticipant">Cancel</button>
          </div>
        </form>
      </div>

    </div>

  </Dialog>
</template>

<style scoped>
.participant-item {
}

.participant-link {
  color: inherit;
  text-decoration: none;
}

.participant-actions {
  display: none;
}

.participant-item:hover .participant-actions {
  display: flex;
}

.participant-item:hover .participant-link {
  @apply text-blue-500 underline;
}

</style>

<script>
import DialogFrame from './dialog_frame.vue'
import Popover from './ConfirmPopover.vue'
import { postData } from '../lib/fetch'

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
} from "@headlessui/vue";

export default {
  inject: ['logged_in', 'ticketID'],
  emits: ['saveParticipant'],
  props: {
    participants: {
      type: Array,
      default: []
    }
  },
  components: { DialogFrame, Popover, Dialog, DialogOverlay, DialogTitle },
  data() {
    return {
      dialogOpen: true,
      editableParticipant: null
    }
  },
  computed: {
    formAction() {
      if(!this.editableParticipant) return "";
      else if(this.editableParticipant.id) {
        return `/tickets/${this.ticketID}/participants/${this.editableParticipant.id}`;
      }
      else {
        return `/tickets/${this.ticketID}/participants`
      }
    },
    formMethod() {
      if(!this.editableParticipant) return "";
      else if(this.editableParticipant.id) {
        return 'PATCH';
      }
      else {
        return 'POST';
      }
    },
    isVisible() {
      return !!this.editableParticipant
    },
    isLoggedIn() { return !!this.logged_in },
    dialogTitle() {
      return this.editableParticipant.id ? 'Edit Participant' : 'Add Participant'
    }
  },
  methods: {
    showParticipantsDialog(attrs = {}) {
      this.editableParticipant = Object.assign({}, attrs)
    },
    editParticipant(id) {
      const editableParticipant = this.participants.filter(p => p.id === id)[0]
      this.showParticipantsDialog(editableParticipant)
    },
    cancelEditingParticipant() {
      this.editableParticipant = null
    },
    async saveParticipant() {
      let response = { success: null }

      if(this.editableParticipant.id) {
        // Patch existing
        response = await patchData(`/tickets/${this.ticketID}/participants/${this.editableParticipant.id}.json`, { participant: this.editableParticipant })
      } else {
        // Post new
        response = await postData(`/tickets/${this.ticketID}/participants.json`, { participant: this.editableParticipant })
      }

      if(response.success) {
        this.$emit('saveParticipant', response.participant)
        this.cancelEditingParticipant()
      }
    },
    addParticipant() {
      this.editableParticipant = { _show: true, id: null }
    }
  }
}
</script>