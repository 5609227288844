<template>
<form ref="form" :action="`/tickets/${ticketId}/notes`" method="post" multipart id="create-note" @submit.prevent="handleSubmit" @dragover.prevent="handleStartDrag" @dragenter.prevent="handleStartDrag" @dragleave.exact.prevent="handleEndDrag" @drop.prevent="handleFileDrop" :class="['relative', { 'is-hovered': isHovered}]">
  <div v-if="isHovered" class="absolute inset-0 bg-blue-100 bg-opacity-80 flex flex-col gap-3 place-items-center place-content-center rounded-xl p-3 -m-3 border border-solid border-blue-600">
    <i class="fas fa-paperclip fa-3x"></i>
    <p class="text-3xl font-medium">Drop file(s) here to attach to note</p>
  </div>

  <h3 class="mb-4">Add note to ticket</h3>

  <div class="control-group">
    <div class="controls">
      <textarea name="note[body]" id="note_body" cols="30" rows="10" class="w-full border border-gray-400 border-solid p-4" v-model="body"></textarea>
    </div>
  </div>

  <div class="control-group create-note-file">
    <ul v-if="files.length > 0" class="list-none mb-3 flex flex-wrap gap-3">
      <li v-for="(file, index) in files" :key={file}>
        <file-chip allow-remove="true" :filename="file.name" :id="file._id" @remove="removeFile" />
      </li>
    </ul>

    <div class="controls">
      <input type="file" ref="fileInput" multiple class="hidden" @change="handleFileSelection" />
      <button class="btn btn-sm btn-soft" @click.prevent="$refs.fileInput.click()">Attach Files</button>
    </div>
  </div>

  <div class="create-note-actions mt-4">
    <button class="btn" :disabled="!canSubmit">Add Note to Ticket</button>
  </div> 
</form>
</template>

<style scoped>
.is-hovered * {
  pointer-events: none;
}
</style>

<script>
import { postData } from '../lib/fetch'
import ShortUniqueId from 'short-unique-id'

const uid = new ShortUniqueId({ length: 10 });

import FileChip from './FileChip.vue'

const defaultValues = {
  body: "",
  files: [],
  isHovered: false,
  isSubmitting: false
}

export default {
  components: { FileChip },
  props: ['ticketId'],
  data() {
    return this.getDefaultValues()
  },
  inject: ['addNote'],
  computed: {
    canSubmit() {
      return !this.isSubmitting && (this.files.length > 0 || this.body.length > 0)
    }
  },
  methods: {
    getDefaultValues() {
      return Object.assign({}, defaultValues)
    },
    async handleSubmit(ev) {
      console.log("Submitting this way")
      const form = ev.target
      
      const formData = new FormData();
      formData.append("note[body]", this.body)

      this.files.forEach(file => {
        formData.append("note[attachments][]", file)
      });

      this.isSubmitting = true

      postData(form.action, formData)
        .then(function(responseData) {
          this.addNote(responseData)
          this.resetForm()
        }.bind(this))
    },
    resetForm() {
      this.body = ""
      this.files = []
      this.isSubmitting = false
      this.$refs.form.reset()
    },
    handleFileDrop(ev) {
      console.log('File(s) dropped');
      const { dataTransfer } = ev

      dataTransfer.items.forEach(function(item) {
        if(item.getAsFile()) {
          this.addFile(item.getAsFile())
        }
      }.bind(this))

      this.isHovered = false
    },
    handleFileSelection(ev) {
      const target = ev.target
      const fileInput = this.$refs.fileInput

      console.log({ fileInput })
      console.log(fileInput.files)
      fileInput.files.forEach(function(file){
        this.addFile(file)
      }.bind(this))
    },
    handleStartDrag(ev) {
      this.isHovered = true
    },
    handleEndDrag(ev) {
      console.log(ev)
      this.isHovered = false
    },
    removeFile(id) {
      const fileIndex = this.files.findIndex(n => (n.id === id))
      this.files.splice(fileIndex, 1)
    },
    addFile(file) {
      file._id = uid()
      this.files.push(file)
    }
  }
}
</script>